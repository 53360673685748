/** @format */

.skillsDiv {
  position: absolute;
  width: 100%;
  top: 5%;
  bottom: 0;
}
  
.skillsBackgroundImg{
  position: fixed;
  width: 100%; 
  height: 100%;
  object-fit: cover;
}

.skillsContainer {
  height: 100%;
  display: flex;
  align-items: center;
}

.skillsCenterDiv{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 25px;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: fit-content;
    width: fit-content;
    border:solid 1px #e3e3e3;
    border-radius: 16px;
    padding: 7px;
    overflow-x: hidden;
    overflow-y: auto;
}

.skillsLine {
    position: relative;
    width: 100%;
    border: 1px solid #e3e3e3;
    margin: 1px;
}

.skillsIconDiv {
  display: flex;
  align-items: center;  
}

.skillsName {
  margin-left: 10px; /* Adjust the spacing between text and image */
  cursor: pointer; /* Change cursor to a pointer on hover */
  color: 	white; /* Set the text shadow to create a border effect */
  font-size: 14px;
  font-weight: bold;
  font-family: Arial, sans-serif;
  transition: transform 0.3s ease;
  text-align: center;
}

.skillsSelected {
  margin-left: 10px; /* Adjust the spacing between text and image */
  cursor: pointer; /* Change cursor to a pointer on hover */
  font-size: 14px;
  color: 	black;
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
  font-weight: bold;
  font-family: Arial, sans-serif;
  transition: transform 0.3s ease;
  text-align: center;
}

.skillsImg {
  width: 25px; /* Adjust the width of the image */
  height: 25px; /* Adjust the height of the image */
  cursor: pointer;
}

.skillsIconDiv:hover .skillsName{
    transform: translateX(4px);
    text-decoration: none;
}

.skillsIconDiv:hover .skillsSelected{
  transform: translateX(4px);
  text-decoration: none;
}

.skillsMainDiv{
    position: absolute;
    height: 100%;
    width: 70%;
    right: 0;
    bottom: 0;
    display: flex; /* Add this line to enable flexbox layout */
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.skillsImg {
    position: relative;
    margin-top: 5%;
    width: 60%;
    height: 50%;
    margin-left: 15px;
    object-fit: fill;
    /* cursor: zoom-in; */
    border-radius: 6px;
}

.skillsName2 {
    color: 	white;
    margin-top: 5px;
    font-size: 16px;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 2); 
    font-weight: bold;
    font-family: Arial, sans-serif;
    transition: transform 0.3s ease;
    text-align: center;
  }

  .skillsNameSelectable{
    cursor: pointer; /* Change cursor to a pointer on hover */
    color: 	white;
    margin-top: 5px;
    font-size: 16px;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 2); 
    font-weight: bold;
    font-family: Arial, sans-serif;
    transition: transform 0.3s ease;
    text-align: center;
  }

  .skillsNameSelectable:hover{
    color: yellow;
  }

  span:hover{
    cursor: pointer; /* Change cursor to a pointer on hover */
    text-decoration: underline;
  }
  
  @media (max-width: 900px) {
    .skillsMediaDiv {
      height: fit-content;
      margin: auto;
      width: 100%;
      margin-top:10%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .skillsMediaName {
      cursor: pointer; /* Change cursor to a pointer on hover */
      color: 	white;
      margin-top: 15px;
      font-size: 16px;
      text-shadow: 1px 1px 4px rgba(0, 0, 0, 2); 
      font-weight: bold;
      font-family: Arial, sans-serif;
      text-align: center;
    }

    .skillsMediaName2 {
      cursor: pointer; /* Change cursor to a pointer on hover */
      color: 	white;
      margin-top: 15px;
      font-size: 13px;
      font-weight: 300;
      font-family: Arial, sans-serif;
      text-align: center;
    }

    .skillsImg{
      position: relative;
      margin-top: 2%;
      width: 90%;
      height: 50%;
      margin-left: 0;
      object-fit: fill;
      cursor: zoom-in;
      border-radius: 6px;
    }
  }