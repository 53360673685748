/** @format */
.mainDiv{
    background-color: black;
    overflow: auto;
}

.expBackgroundImg{
    position: fixed;
    width: 100%; 
    height: 100%;
    object-fit: cover;
    right: 5px;
    transition: none;
}

.experienceDiv {
  position: absolute;
  width: 100%;
  top: 5%;
  bottom: 0;
}

.blackScreen{
  position: absolute;
  width: 100%;
  top: 0%;
  bottom: 0;
  background-color: black;
  z-index: 1;
}

.experienceContainer {
  height: 100%;
  display: flex;
  align-items: center;
}

.experienceLeftDiv {
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.experienceRightDiv {
  position: relative;
  flex: 1;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.experienceImg {
  position: relative;
  width: 100%;
  height: 70%;
  margin-left: 15px;
  border-radius: 50px;
  object-fit: fill;
  /* cursor: zoom-in; */
}

.experienceImg2 {
    position: relative;
    width: 100%;
    height: 70%;
    margin-right: 15px;
    border-radius: 50px;
    object-fit: fill;
    /* cursor: zoom-in; */
}

  
.experienceIconDiv {
  position: relative;
  margin-top: 15px;
  display: flex;
  gap: 25px;
}

.icon {
  width: 45px;
  height: 45px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.experienceTitle{
    color: white;
    font-size: 34px;
    margin: 0;
    font-weight: bold;
    font-family: monospace, sans-serif;
  }
  .experienceText{
    color: 	white;
    font-size: 16px;
    font-weight: 500;
    font-family: Arial, sans-serif;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 2); 
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 16px;
    padding: 15px;
    margin-bottom: 40px;
  }

.experienceDiv {
  opacity: 0; /* Set initial opacity to 0 */
  animation: fade-in 0.5s ease-in-out forwards; /* Apply fade-in animation */
}

@keyframes fade-in {
  from {
    opacity: 0; /* Start with opacity 0 */
    transform: translateY(20px); /* Optional: Apply initial transformation */
  }
  to {
    opacity: 1; /* End with opacity 1 */
    transform: translateY(0); /* Optional: Apply final transformation */
  }
}

::-webkit-scrollbar {
    width: 5px;
    }
    
    ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 5px;
    }
    
    ::-webkit-scrollbar-thumb {
    background:#e3e3e3; 
    border-radius: 5px;
    }
    
    ::-webkit-scrollbar-thumb:hover {
    background:rgb(54, 56, 58); 
}

.lineAnimation {
  width: 100%;
  height: 2px;
  background-color: #000;
  position: relative;
  z-index: 2;
}

@keyframes lineAnimation {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 10%;
    left: 0;
  }
  100% {
    width: 0%;
    left: 100%;
  }
}

.lineAnimation::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  animation: lineAnimation 1s linear infinite;
}

@media (max-width: 900px) {
  .experienceContainer {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .experienceTitle{
    color: white;
    font-size: 24px;
    margin-bottom: 5px;
    font-weight: bold;
    font-family: monospace, sans-serif;
  }
  .experienceImg {
    position: relative;
    width: 90%;
    height: 200px;
    margin-left: 0;
    border-radius: 50px;
    object-fit: fill;
    cursor: zoom-in;
    border-radius: 12px;
  }

  .experienceIconDiv {
    position: relative;
    margin-top: 15px;
    display: flex;
    gap: 25px;
  }

  .icon {
    width: 30px;
    height: 30px;
    transition: transform 0.3s ease;
    cursor: pointer;
  }

  .experienceText{
    color: 	white;
    font-size: 14px;
    padding: 15px;
    margin-bottom: 0px;
  }
}