.mainDiv{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .backgroundImg{
    position: absolute;
    width: 100%; 
    height: 100%;
    object-fit: cover;
    opacity: 0.9;
  }
  
  .middleDiv{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .middleTop{
    color: white;
    font-size: 28px;
    margin: 0;
    font-weight: bold;
    font-family: monospace, sans-serif;
  }
  .middleHeader{
    margin: 0;
    color: white;
    font-size: 28px;
    font-weight: bold;
    font-family: monospace, sans-serif;
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
  }

  .middleText{
    color: 	white;
    font-size: 16px;
    font-weight: 500;
    font-family: Arial, sans-serif;
    text-align: center;
    margin: 5px;
    margin-left: 10%;
    margin-right: 10%;
    border: 1px solid whitesmoke;
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
    border-radius: 16px;
    padding: 15px;
  }

  .middleText:hover{
    background-color: black;
  }

  .middleBottom{
    margin: 0; margin-top: 15px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    font-family: monospace, sans-serif;
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
  }

  .iconDiv {
    position: relative;
    display: flex;
    gap: 25px;
  }

  .icon{
      width: 45px;
      height: 45px;
      transition: transform 0.3s ease;
      cursor: pointer;
  }

  .companyMainDiv{
    position: relative;
    display: flex;
    gap: 25px;
    width: 100%;
    justify-content: center;
  }
  
  .companyIcon{
    width: 45px;
    height: 45px;
    transition: transform 0.3s ease;
    cursor: pointer;
    margin-left: 4px;
}

  .icon:hover,
  .companyIcon:hover{
    transform: translateY(-4px);
  }

  .companiesDiv{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
  }

  .companyLabel{
    margin: 0; margin-top: 5px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
  }


  @media (max-width: 900px) {
    .textDiv {
        gap: 25px;
    }
  
    .textLabel {
      font-size: 10px;
    }
  
    .textSelected {
      font-size: 10px;
    }

    .middleTop{
      font-size: 16px;
    }
    .middleHeader{
      font-size: 24px;
    }

    .middleText{
      font-size: 10px;
    }

    .icon{
      width: 25px;
      height: 25px;
      transition: transform 0.3s ease;
      cursor: pointer;
    }

    .companiesDiv{
      width: 95%;
    }

    .companyIcon{
      width: 30px;
      height: 30px;
      transition: transform 0.3s ease;
      cursor: pointer;
    }

  }